import { addMethod, setLocale, string } from 'yup'
import { Masks } from '@/constants/Masks'
import * as yupLocales from 'yup-locales'
import * as uuid from 'uuid'

export const isId = (value: string | undefined) => {
  if (!value) return false
  return uuid.validate(value)
}

export const isShipmentShortId = (str: string | undefined) => {
  if (!str) return false
  return Boolean(str.match(/^\d+-\d+$/))
}
addMethod(string, 'id', function id(customError?: string) {
  return this.meta({
    mask: Masks.id,
    placeholder: '',
  }).test(
    'is-id',
    ({ label, path }) => customError || `${label || path} ― в неверном формате`,
    (value) => {
      return !value || isId(value)
    },
  )
})
addMethod(string, 'date', function id(customError?: string) {
  return this.meta({
    mask: Masks.id,
    placeholder: '',
  }).test(
    'is-date',
    ({ label, path }) => customError || `${label || path} ― в неверном формате`,
    (value) => {
      if (value) {
        const d = new Date(value)
        return Boolean(d)
      }
      return true
    },
  )
})
addMethod(string, 'phone', function phone(customError?: string) {
  return this.meta({
    mask: Masks.phone,
    placeholder: '+7 (000) 000-00-00',
  })
    .transform((currentValue) => {
      return currentValue
        .replace(/\D/g, '')
        .replace(/^8/, '+7')
        .replace(/^7/, '+7')
    })
    .test(
      'is-phone',
      ({ label, path }) =>
        customError || `${label || path} ― в неверном формате`,
      (value) => {
        return !value || Boolean(value && value.match(/\+7\d{10}/))
      },
    )
})

setLocale({
  ...yupLocales.ru,
  mixed: {
    ...yupLocales.ru.mixed,
    // eslint-disable-next-line no-template-curly-in-string
    required: '${path} – нужно заполнить',
  },
})
