import { emptySplitPhpApi as api } from '../emptyApi'
export const addTagTypes = [
  'Clients',
  'DeliveryServices',
  'Drivers',
  'Goods',
  'Invoices',
  'Locations',
  'Shipments',
  'Vehicles',
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getInternalClientSettingsById: build.query<
        GetInternalClientSettingsByIdApiResponse,
        GetInternalClientSettingsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/client-settings`,
          params: { partnerId: queryArg.partnerId },
        }),
        providesTags: ['Clients'],
      }),
      getInternalClientsList: build.query<
        GetInternalClientsListApiResponse,
        GetInternalClientsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients`,
          params: {
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Clients'],
      }),
      postInternalClients: build.mutation<
        PostInternalClientsApiResponse,
        PostInternalClientsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients`,
          method: 'POST',
          body: queryArg.clientStoreRequest,
        }),
        invalidatesTags: ['Clients'],
      }),
      getClientsById: build.query<
        GetClientsByIdApiResponse,
        GetClientsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1.1/clients/${queryArg.id}` }),
        providesTags: ['Clients'],
      }),
      putInternalClientsById: build.mutation<
        PutInternalClientsByIdApiResponse,
        PutInternalClientsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.clientUpdateRequest,
        }),
        invalidatesTags: ['Clients'],
      }),
      deleteInternalClientsById: build.mutation<
        DeleteInternalClientsByIdApiResponse,
        DeleteInternalClientsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Clients'],
      }),
      getInternalDeliveryServicesList: build.query<
        GetInternalDeliveryServicesListApiResponse,
        GetInternalDeliveryServicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services`,
          params: {
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            isActive: queryArg.isActive,
          },
        }),
        providesTags: ['DeliveryServices'],
      }),
      postInternalDeliveryServices: build.mutation<
        PostInternalDeliveryServicesApiResponse,
        PostInternalDeliveryServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services`,
          method: 'POST',
          body: queryArg.deliveryServiceStoreRequest,
        }),
        invalidatesTags: ['DeliveryServices'],
      }),
      getInternalDeliveryServicesById: build.query<
        GetInternalDeliveryServicesByIdApiResponse,
        GetInternalDeliveryServicesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services/${queryArg.id}`,
        }),
        providesTags: ['DeliveryServices'],
      }),
      putInternalDeliveryServicesById: build.mutation<
        PutInternalDeliveryServicesByIdApiResponse,
        PutInternalDeliveryServicesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.deliveryServiceUpdateRequest,
        }),
        invalidatesTags: ['DeliveryServices'],
      }),
      deleteInternalDeliveryServicesById: build.mutation<
        DeleteInternalDeliveryServicesByIdApiResponse,
        DeleteInternalDeliveryServicesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['DeliveryServices'],
      }),
      getInternalDriversList: build.query<
        GetInternalDriversListApiResponse,
        GetInternalDriversListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers`,
          params: {
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            isActive: queryArg.isActive,
          },
        }),
        providesTags: ['Drivers'],
      }),
      postInternalDrivers: build.mutation<
        PostInternalDriversApiResponse,
        PostInternalDriversApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers`,
          method: 'POST',
          body: queryArg.driverStoreRequest,
        }),
        invalidatesTags: ['Drivers'],
      }),
      getInternalDriversById: build.query<
        GetInternalDriversByIdApiResponse,
        GetInternalDriversByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers/${queryArg.id}`,
        }),
        providesTags: ['Drivers'],
      }),
      putInternalDriversById: build.mutation<
        PutInternalDriversByIdApiResponse,
        PutInternalDriversByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.driverUpdateRequest,
        }),
        invalidatesTags: ['Drivers'],
      }),
      deleteInternalDriversById: build.mutation<
        DeleteInternalDriversByIdApiResponse,
        DeleteInternalDriversByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Drivers'],
      }),
      getInternalGoodsList: build.query<
        GetInternalGoodsListApiResponse,
        GetInternalGoodsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/goods`,
          params: {
            partnerId: queryArg.partnerId,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Goods'],
      }),
      postInternalGoods: build.mutation<
        PostInternalGoodsApiResponse,
        PostInternalGoodsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/goods`,
          method: 'POST',
          body: queryArg.goodStoreRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Goods'],
      }),
      getInternalGoodsById: build.query<
        GetInternalGoodsByIdApiResponse,
        GetInternalGoodsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1.1/goods/${queryArg.id}` }),
        providesTags: ['Goods'],
      }),
      getInternalInvoicesList: build.query<
        GetInternalInvoicesListApiResponse,
        GetInternalInvoicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/invoices`,
          params: {
            clientIds: queryArg.clientIds,
            statuses: queryArg.statuses,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            periodFrom: queryArg.periodFrom,
            periodTo: queryArg.periodTo,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Invoices'],
      }),
      postInternalInvoices: build.mutation<
        PostInternalInvoicesApiResponse,
        PostInternalInvoicesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/invoices`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Invoices'],
      }),
      patchInternalInvoicesUpdateAmountPaid: build.mutation<
        PatchInternalInvoicesUpdateAmountPaidApiResponse,
        PatchInternalInvoicesUpdateAmountPaidApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/invoices/${queryArg.invoiceId}`,
          method: 'PATCH',
          body: queryArg.invoiceUpdateAmountPaidRequest,
        }),
        invalidatesTags: ['Invoices'],
      }),
      getInternalLocationsList: build.query<
        GetInternalLocationsListApiResponse,
        GetInternalLocationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations`,
          params: {
            type: queryArg['type'],
            partnerId: queryArg.partnerId,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Locations'],
      }),
      putInternalLocations: build.mutation<
        PutInternalLocationsApiResponse,
        PutInternalLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations`,
          method: 'PUT',
          body: queryArg.locationStoreRequest,
        }),
        invalidatesTags: ['Locations'],
      }),
      postInternalLocations: build.mutation<
        PostInternalLocationsApiResponse,
        PostInternalLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations`,
          method: 'POST',
          body: queryArg.locationStoreRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Locations'],
      }),
      getInternalLocationsById: build.query<
        GetInternalLocationsByIdApiResponse,
        GetInternalLocationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations/${queryArg.id}`,
        }),
        providesTags: ['Locations'],
      }),
      getInternalCanHaveQubyWarehouse: build.query<
        GetInternalCanHaveQubyWarehouseApiResponse,
        GetInternalCanHaveQubyWarehouseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/can-have-quby-warehouse`,
          params: {
            pickupLocationId: queryArg.pickupLocationId,
            deliveryLocationId: queryArg.deliveryLocationId,
            partnerId: queryArg.partnerId,
          },
        }),
        providesTags: ['Locations'],
      }),
      getInternalShipmentsList: build.query<
        GetInternalShipmentsListApiResponse,
        GetInternalShipmentsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments`,
          params: {
            ids: queryArg.ids,
            partnerIds: queryArg.partnerIds,
            deliveryServiceIds: queryArg.deliveryServiceIds,
            statuses: queryArg.statuses,
            types: queryArg.types,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            plannedDeliveryDateFrom: queryArg.plannedDeliveryDateFrom,
            plannedDeliveryDateTo: queryArg.plannedDeliveryDateTo,
            closedDateFrom: queryArg.closedDateFrom,
            closedDateTo: queryArg.closedDateTo,
            orderBy: queryArg.orderBy,
            with: queryArg['with'],
            order: queryArg.order,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Shipments'],
      }),
      postInternalShipments: build.mutation<
        PostInternalShipmentsApiResponse,
        PostInternalShipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments`,
          method: 'POST',
          body: queryArg.shipmentStoreRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipments'],
      }),
      getInternalShipmentsById: build.query<
        GetInternalShipmentsByIdApiResponse,
        GetInternalShipmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments/${queryArg.id}`,
        }),
        providesTags: ['Shipments'],
      }),
      patchInternalShipments: build.mutation<
        PatchInternalShipmentsApiResponse,
        PatchInternalShipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments/${queryArg.shipmentId}`,
          method: 'PATCH',
          body: queryArg.shipmentUpdateRequest,
        }),
        invalidatesTags: ['Shipments'],
      }),
      getInternalShipmentsRegisterExport: build.query<
        GetInternalShipmentsRegisterExportApiResponse,
        GetInternalShipmentsRegisterExportApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/export/shipments-register`,
          params: {
            clientIds: queryArg.clientIds,
            statuses: queryArg.statuses,
            types: queryArg.types,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            desiredTimeFrom: queryArg.desiredTimeFrom,
            desiredTimeTo: queryArg.desiredTimeTo,
            actualDateFrom: queryArg.actualDateFrom,
            actualDateTo: queryArg.actualDateTo,
            invoiceIds: queryArg.invoiceIds,
            desiredColumn: queryArg.desiredColumn,
          },
        }),
        providesTags: ['Shipments'],
      }),
      getInternalDataClientCalculatorTariffs: build.query<
        GetInternalDataClientCalculatorTariffsApiResponse,
        GetInternalDataClientCalculatorTariffsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/data-for-calculator-get-tariffs`,
          params: {
            partnerId: queryArg.partnerId,
            senderLocationId: queryArg.senderLocationId,
            receiverLocationId: queryArg.receiverLocationId,
          },
        }),
        providesTags: ['Shipments'],
      }),
      getInternalVehiclesList: build.query<
        GetInternalVehiclesListApiResponse,
        GetInternalVehiclesListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles`,
          params: {
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            isActive: queryArg.isActive,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      postInternalVehicles: build.mutation<
        PostInternalVehiclesApiResponse,
        PostInternalVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles`,
          method: 'POST',
          body: queryArg.vehicleStoreRequest,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      getInternalVehiclesById: build.query<
        GetInternalVehiclesByIdApiResponse,
        GetInternalVehiclesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles/${queryArg.id}`,
        }),
        providesTags: ['Vehicles'],
      }),
      putInternalVehiclesById: build.mutation<
        PutInternalVehiclesByIdApiResponse,
        PutInternalVehiclesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.vehicleUpdateRequest,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      deleteInternalVehiclesById: build.mutation<
        DeleteInternalVehiclesByIdApiResponse,
        DeleteInternalVehiclesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Vehicles'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as qubyApiPhp }
export type GetInternalClientSettingsByIdApiResponse =
  /** status 200 Client settings */ ClientSettingsResource
export type GetInternalClientSettingsByIdApiArg = {
  partnerId?: string
}
export type GetInternalClientsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: ClientResource[]
  }
export type GetInternalClientsListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalClientsApiResponse =
  /** status 201 Successfully created */ ClientResource
export type PostInternalClientsApiArg = {
  clientStoreRequest: ClientStoreRequest
}
export type GetClientsByIdApiResponse = /** status 200 Success */ ClientResource
export type GetClientsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalClientsByIdApiResponse =
  /** status 202 Successfully updated */ ClientResource
export type PutInternalClientsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  clientUpdateRequest: ClientUpdateRequest
}
export type DeleteInternalClientsByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalClientsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalDeliveryServicesListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: DeliveryServiceResource[]
  }
export type GetInternalDeliveryServicesListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  /** filter by field isActive */
  isActive?: boolean
}
export type PostInternalDeliveryServicesApiResponse =
  /** status 201 Successfully created */ DeliveryServiceResource
export type PostInternalDeliveryServicesApiArg = {
  deliveryServiceStoreRequest: DeliveryServiceStoreRequest
}
export type GetInternalDeliveryServicesByIdApiResponse =
  /** status 200 Success */ DeliveryServiceResource
export type GetInternalDeliveryServicesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalDeliveryServicesByIdApiResponse =
  /** status 202 Successfully updated */ DeliveryServiceResource
export type PutInternalDeliveryServicesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  deliveryServiceUpdateRequest: DeliveryServiceUpdateRequest
}
export type DeleteInternalDeliveryServicesByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalDeliveryServicesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalDriversListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: DriverResource[]
  }
export type GetInternalDriversListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  /** filter by field isActive */
  isActive?: boolean
}
export type PostInternalDriversApiResponse =
  /** status 201 Successfully created */ DriverResource
export type PostInternalDriversApiArg = {
  driverStoreRequest: DriverStoreRequest
}
export type GetInternalDriversByIdApiResponse =
  /** status 200 Success */ DriverResource
export type GetInternalDriversByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalDriversByIdApiResponse =
  /** status 202 Successfully updated */ DriverResource
export type PutInternalDriversByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  driverUpdateRequest: DriverUpdateRequest
}
export type DeleteInternalDriversByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalDriversByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalGoodsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: GoodResource[]
  }
export type GetInternalGoodsListApiArg = {
  /** Uuid of a client. Only for calls by quby employees */
  partnerId: string
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalGoodsApiResponse =
  /** status 201 Created */ GoodResource
export type PostInternalGoodsApiArg = {
  partnerId: string
  goodStoreRequest: GoodStoreRequest
}
export type GetInternalGoodsByIdApiResponse =
  /** status 200 Success */ GoodResource
export type GetInternalGoodsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalInvoicesListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: InvoiceResource[]
  }
export type GetInternalInvoicesListApiArg = {
  /** Filter by client ids */
  clientIds?: string[]
  /** Filter by invoice statuses */
  statuses?: InvoiceStatusEnum[]
  /** Created after or equal to provided date */
  createdDateFrom?: string
  /** Created before or equal to provided date */
  createdDateTo?: string
  /** Period of the invoice must intersect with provided date */
  periodFrom?: string
  /** Period of the invoice must intersect with provided date */
  periodTo?: string
  /** Parameter to order the results by */
  orderBy?: InvoiceOrderByEnum
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalInvoicesApiResponse =
  /** status 201 Created */ InvoiceResource
export type PostInternalInvoicesApiArg = {
  body: {
    /** Uuid of a client. Only for calls by Quby employees */
    partnerId: string
    /** invoice date from */
    from: string
    /** invoice date to */
    to: string
    /** Register of the provided services in .xlsx format* */
    providedServicesRegister: any
    /** Comment for the created invoice */
    comment?: string
  }
}
export type PatchInternalInvoicesUpdateAmountPaidApiResponse =
  /** status 200 Successfully updated */ InvoiceResource
export type PatchInternalInvoicesUpdateAmountPaidApiArg = {
  /** Uuid of a invoice */
  invoiceId: string
  invoiceUpdateAmountPaidRequest: InvoiceUpdateAmountPaidRequest
}
export type GetInternalLocationsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: LocationResource[]
  }
export type GetInternalLocationsListApiArg = {
  /** Type of entity */
  type: LocationTypes
  /** Id (UUID) of a model */
  partnerId?: string
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PutInternalLocationsApiResponse =
  /** status 202 Data updated */ LocationResource
export type PutInternalLocationsApiArg = {
  /** Id (UUID) of a model */
  id: string
  locationStoreRequest: LocationStoreRequest
}
export type PostInternalLocationsApiResponse =
  /** status 201 Created */ LocationResource
export type PostInternalLocationsApiArg = {
  partnerId: string
  locationStoreRequest: LocationStoreRequest
}
export type GetInternalLocationsByIdApiResponse =
  /** status 200 Success */ LocationResource
export type GetInternalLocationsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalCanHaveQubyWarehouseApiResponse =
  /** status 200 Success */ {
    canHaveQubyWarehouse: boolean
  }
export type GetInternalCanHaveQubyWarehouseApiArg = {
  /** UUID of pickup location */
  pickupLocationId: string
  /** UUID of delivery location */
  deliveryLocationId: string
  /** UUID of client */
  partnerId: string
}
export type GetInternalShipmentsListApiResponse =
  /** status 200 The data of the shipments */ PaginationResponse & {
    data: ShipmentAuthResource[]
  }
export type GetInternalShipmentsListApiArg = {
  /** Filter by ids of shipments */
  ids?: string[]
  /** Filter by client ids */
  partnerIds?: string[]
  /** Filter by delivery service ids */
  deliveryServiceIds?: string[]
  /** Filter by shipment statuses */
  statuses?: ShipmentStatusEnum[]
  /** Filter by shipment types */
  types?: ShipmentTypesEnum[]
  /** Filter by shipments created after or equal to provided date */
  createdDateFrom?: string
  /** Filter by shipments created before or equal to provided date */
  createdDateTo?: string
  /** Filter by shipments updated after or equal to provided date */
  updatedDateFrom?: string
  /** Filter by shipments updated before or equal to provided date */
  updatedDateTo?: string
  /** Filter by shipments planned for delivery after or equal to provided date */
  plannedDeliveryDateFrom?: string
  /** Filter by shipments planned for delivery before or equal to provided date */
  plannedDeliveryDateTo?: string
  /** Filter by shipments closed (completed) after or equal to provided date */
  closedDateFrom?: string
  /** Filter by shipments closed (completed) before or equal to provided date */
  closedDateTo?: string
  /** Parameter to order the results by */
  orderBy?: ShipmentOrderByTypeEnum
  /** Preload specific relationns for the response */
  with?: ShipmentRelationsEnum[]
  /** Parameter to order the results */
  order?: BaseOrderTypeEnum
  /** Search query */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalShipmentsApiResponse =
  /** status 201 Successfully created shipment */ ShipmentAuthResource
export type PostInternalShipmentsApiArg = {
  /** Uuid of a client. Only for calls by Quby employees */
  partnerId: string
  shipmentStoreRequest: ShipmentStoreRequest
}
export type GetInternalShipmentsByIdApiResponse =
  /** status 200 The data of the shipments */ ShipmentAuthResource
export type GetInternalShipmentsByIdApiArg = {
  /** Uuid or full ordinal number of a shipment */
  id?: string
}
export type PatchInternalShipmentsApiResponse =
  /** status 201 Successfully updated shipment */ ShipmentAuthResource
export type PatchInternalShipmentsApiArg = {
  /** Uuid of a shipment */
  shipmentId: string
  shipmentUpdateRequest: ShipmentUpdateRequest
}
export type GetInternalShipmentsRegisterExportApiResponse =
  /** status 200 Successful getting shipment export file */ Blob
export type GetInternalShipmentsRegisterExportApiArg = {
  /** Filter by client ids of shipments */
  clientIds?: string[]
  /** Filter by shipment statuses */
  statuses?: ShipmentStatusEnum[]
  /** Filter by shipment types */
  types?: ShipmentTypesEnum[]
  /** Filter by shipments created after or equal to provided date */
  createdDateFrom?: string
  /** Filter by shipments created before or equal to provided date */
  createdDateTo?: string
  /** Filter by shipments updated after or equal to provided date */
  updatedDateFrom?: string
  /** Filter by shipments updated before or equal to provided date */
  updatedDateTo?: string
  /** Filter by shipments planned for delivery after or equal to provided date */
  desiredTimeFrom?: string
  /** Filter by shipments planned for delivery before or equal to provided date */
  desiredTimeTo?: string
  /** Filter by shipments actual for delivery after or equal to provided date */
  actualDateFrom?: string
  /** Filter by shipments actual for delivery before or equal to provided date */
  actualDateTo?: string
  /** array of invoice UUIDs related to the shipment */
  invoiceIds?: string[]
  /** Column list for XLSX file */
  desiredColumn: ShipmentsRegisterExportColumnsEnum[]
}
export type GetInternalDataClientCalculatorTariffsApiResponse =
  /** status 201 The data of the tariff */ DataForCalculatorGetTariffsResource
export type GetInternalDataClientCalculatorTariffsApiArg = {
  /** Partner ID */
  partnerId: string
  /** senderLocationId */
  senderLocationId: string
  /** receiverLocationId */
  receiverLocationId: string
}
export type GetInternalVehiclesListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: VehicleResource[]
  }
export type GetInternalVehiclesListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  /** filter by field isActive */
  isActive?: boolean
}
export type PostInternalVehiclesApiResponse =
  /** status 201 Successfully created */ VehicleResource
export type PostInternalVehiclesApiArg = {
  vehicleStoreRequest: VehicleStoreRequest
}
export type GetInternalVehiclesByIdApiResponse =
  /** status 200 Success */ VehicleResource
export type GetInternalVehiclesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalVehiclesByIdApiResponse =
  /** status 202 Successfully updated */ VehicleResource
export type PutInternalVehiclesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  vehicleUpdateRequest: VehicleUpdateRequest
}
export type DeleteInternalVehiclesByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalVehiclesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type ClientSettingsResource = {
  /** % от объявленной ценности, который будет начислен за страхование груза в МСК */
  cargoInsuranceDefaultPercent: number
  cargoInsuranceMinCharge: number
  cargoInsuranceRegionalPercent: number
  cashOnDeliveryCardPercent: number
  cashOnDeliveryMinCharge: number
  cashOnDeliveryPercent: number
  crateCost: number
  crateCostMin: number
  crateCostPercent: number
  defaultShipmentStatus: string
  discountPercent: number
  documentReturnShipmentAutoCreation: boolean
  documentReturningCost: number
  isCargoPackageBasedLoadOperations: boolean
  isDeliveryServiceVisible: boolean
  isDemo: boolean
  isVolumeWeightEnabled: boolean
  overpricingForExtraCargoCost: number
  pickupFboBoxMskCost: number
  pickupFboBoxMskRegionCost: number
  pickupFboPalletMskCost: number
  pickupFboPalletMskRegionCost: number
  providingScanDocumentsCost: number
  redeliveryPercent: number
  returnPercent: number
  shipmentAutoConfirmationEnabled: boolean
  shipmentConfirmationCost: number
  volumeWeightValue: number
  defaultPartnerWarehouseId: string
  palletFormationCost: number
  palletSellingCost: number
  hourOfOneTimeServicesCost: number
}
export type PaginationResponse = {
  /** Metadata */
  meta: {
    currentPage: number
    totalPages: number
    perPage: number
    count: number
  }
}
export type ClientUserResource = {
  /** ID (UUID) */
  id: string
  email: string
  fullName: string
}
export type ClientWarehouseResource = {
  /** ID (UUID) */
  id: string
  type?: string
  name: string | null
  companyName?: string | null
  fullName?: string
  addressId?: string
  addressString: string
  workingTimeFrom: string | null
  workingTimeTo: string | null
}
export type ClientResource = {
  /** Name of organization */
  legalName: string
  legalAddress: string
  actualAddress: string
  phoneNumber: string
  /** ФИО директора организации */
  director: string
  inn: string
  ogrn: string
  bankName: string
  /** БИК банка */
  bic: string
  /** Корреспондентский счет банка */
  correspondentAccount: string
  /** Расчетный счет клиента в банке */
  checkingAccount: string
  /** Статус работы с нами */
  status: 'ACTIVE' | 'DEMO'
  /** Client ID (UUID) */
  id: string
  /** Ordinal number of a client */
  ordinalNumber?: number
  /** Duplicate legal name */
  name: string
  /** Concatenation of legal form and legal name */
  fullName: string
  /** Legal form of organization */
  legalForm: '\u041E\u041E\u041E' | '\u0418\u041F'
  kpp: string
  okpo: string
  /** Номер договора с нами */
  contractNumber: string
  /** Дата договора с нами */
  contractDate: string
  /** Пользователи клиента */
  users: ClientUserResource[]
  /** Склады клиента */
  warehouses: ClientWarehouseResource[]
  /** Date and time when client was deleted */
  deletedAt?: string
  settings?: ClientSettingsResource
}
export type ClientUserRequest = {
  email: string
  password: string
  fullName: string
}
export type ClientWarehouseRequest = {
  name: string
  addressString: string
  phoneNumber: string
  workingTimeFrom: string
  workingTimeTo: string
}
export type ClientStoreRequest = {
  /** Name of organization */
  legalName: string
  legalAddress: string
  actualAddress: string
  phoneNumber: string
  /** ФИО директора организации */
  director: string
  inn: string
  ogrn: string
  bankName: string
  /** БИК банка */
  bic: string
  /** Корреспондентский счет банка */
  correspondentAccount: string
  /** Расчетный счет клиента в банке */
  checkingAccount: string
  /** Статус работы с нами */
  status?: 'ACTIVE' | 'DEMO'
  /** Legal form of organization */
  legalForm: '\u041E\u041E\u041E' | '\u0418\u041F'
  /** Дата договора с нами */
  contractDate: string
  /** Пользователи клиента */
  users: ClientUserRequest[]
  /** Склады клиента */
  warehouses: ClientWarehouseRequest[]
  /** Номер договора с нами */
  contractNumber?: string
}
export type ClientUpdateRequest = {
  /** Name of organization */
  legalName?: string
  legalAddress?: string
  actualAddress?: string
  phoneNumber?: string
  /** ФИО директора организации */
  director?: string
  inn?: string
  ogrn?: string
  bankName?: string
  /** БИК банка */
  bic?: string
  /** Корреспондентский счет банка */
  correspondentAccount?: string
  /** Расчетный счет клиента в банке */
  checkingAccount?: string
  /** Статус работы с нами */
  status?: 'ACTIVE' | 'DEMO'
}
export type CommonDeliveryServiceProperties = {
  /** Display name */
  name?: string
  /** Legal name */
  legalName?: string
  /** Url */
  url?: string
  /** Phone number */
  phoneNumber?: string
  /** Dlv contract id */
  dlvContractId?: string
  /** Доступен к использованию */
  isActive?: boolean
}
export type DeliveryServiceResource = CommonDeliveryServiceProperties & {
  /** Delivery Service`s UUID */
  id?: string
  /** createdAt */
  createdAt?: string
}
export type DeliveryServiceStoreRequest = CommonDeliveryServiceProperties
export type DeliveryServiceUpdateRequest = CommonDeliveryServiceProperties
export type DriverResource = {
  /** Driver`s UUID */
  id: string
  /** Full name */
  fullName: string
  /** Водительские права */
  driverLicence: string
  /** Номер */
  phoneNumber: string
  /** Дата создания */
  createdAt: string
  /** ID кем создано */
  createdBy: string
  /** Заблокирован? */
  fired: boolean
}
export type DriverStoreRequest = {
  /** Full name */
  fullName: string
  /** Водительские права */
  driverLicence: string
  /** Номер */
  phoneNumber: string
}
export type DriverUpdateRequest = DriverStoreRequest & {
  /** Заблокирован? */
  fired?: boolean
}
export type GoodResource = {
  /** Driver`s UUID */
  id: any
  /** Name */
  name: string
  /** VendorCode */
  vendorCode: string
  /** ExternalId */
  externalId: string
  /** VatRate */
  vatRate: string
  /** Price */
  price: string
}
export type BaseGoodData = {
  name?: string
  vendorCode?: string
  externalId?: string
  vatRate?: string
  price?: number
}
export type GoodStoreRequest = BaseGoodData
export type InvoiceStatus = 'NEW'
export type InvoiceResource = {
  /** Invoice ID (UUID) */
  id: string
  status: InvoiceStatus
  /** Invoice name */
  name: string
  /** Date From */
  dateFrom: string
  /** Date To */
  dateTo: string
  /** Sum */
  sum: number
  /** Amount Paid */
  amountPaid: number
  /** Comment */
  comment: string
  /** Date and time when shipment was created */
  createdAt: string
  /** Date and time when shipment was updated last time */
  updatedAt: string
  /** Temporary url to download provided Services Register */
  providedServicesRegisterUrl?: string
}
export type InvoiceStatusEnum = 'NEW' | 'SENT' | 'PARTIALLY_PAID' | 'PAID'
export type InvoiceOrderByEnum =
  | 'clientId'
  | 'dateFrom'
  | 'dateTo'
  | 'sum'
  | 'status'
  | 'amountPaid'
  | 'createdDate'
export type InvoiceUpdateAmountPaidRequest = {
  /** Amount Paid */
  amountPaid: number
}
export type LocationTypes =
  | 'CLIENT_WAREHOUSE'
  | 'QUBY_WAREHOUSE'
  | 'MARKETPLACE_WAREHOUSE'
  | 'ADDRESS'
export type LocationCommonProperties = {
  /** Location`s UUID */
  id: string
  type: LocationTypes
  /** fullName */
  name: string
  /** fullName */
  fullName: string
  /** companyName */
  companyName: string
  /** addressId`s UUID */
  addressId: string
  /** addressString */
  addressString: string
  floor: number
  /** phoneNumber */
  phoneNumber: string
  /** additionalPhoneNumber */
  additionalPhoneNumber: string
  /** workingTimeFrom */
  workingTimeFrom: string
  /** workingTimeTo */
  workingTimeTo: string
  /** comment */
  comment: string
}
export type LocationResource = LocationCommonProperties
export type Location = {
  fullName?: string
  phoneNumber?: string
  additionalPhoneNumber?: string
  comment?: string
  addressString?: string
  floor?: number
}
export type LocationStoreRequest = Location
export type LocationShortResource = {
  /** Location`s UUID */
  id: string
  type: LocationTypes
  /** fullName */
  fullName: string
  /** addressString */
  addressString: string
  /** phoneNumber */
  phoneNumber: string
}
export type TimeFrameData = {
  date?: string
  timeFrom?: string
  timeTo?: string
}
export type PickupData = {
  /** UUID of sender location. Could only be provided without `senderLocation` field */
  locationId: string
  location: LocationShortResource
  timeFrame: TimeFrameData
  /** Comment for pickup */
  comment?: string
}
export type DeliveryData = {
  /** UUID of receiver location. Could only be provided without `receiverLocation` field */
  locationId: string
  location: LocationShortResource
  timeFrame: TimeFrameData
  /** Comment for delivery */
  comment?: string
}
export type ShipmentAdditionalServicesEnum =
  | 'PICKUP_MANUAL_LIFT'
  | 'PICKUP_ELEVATOR_LIFT'
  | 'DELIVERY_MANUAL_LIFT'
  | 'DELIVERY_ELEVATOR_LIFT'
  | 'CARGO_INSURANCE'
  | 'CASH_ON_DELIVERY_CASH'
  | 'CASH_ON_DELIVERY_CARD'
  | 'DOCUMENTS_RETURN'
  | 'REVERSE_SHIPMENT'
  | 'SHIPMENT_CONFIRMATION'
  | 'PALLET_FORMATION'
  | 'PALLET_SELLING'
  | 'ONE_TIME_SERVICES'
  | 'SCANS'
  | 'CRATING'
export type CargoPackageTypes = 'DOCUMENTS' | 'BOX' | 'PALLET' | 'PILE'
export type GoodData = BaseGoodData & {
  amount?: number
}
export type GoodReference = {
  /** Id of good in quby database */
  id: string
  /** Amount of goods with provided id */
  amount: number
  /** Price */
  price: number
}
export type CargoPackageData = {
  packageType?: CargoPackageTypes
  length?: number
  width?: number
  height?: number
  weight?: number
  estimatedCost?: number
  barcodeNumber?: string
  /** Array of goods */
  goods?: (GoodData | GoodReference)[]
}
export type InternalCargoPackageData = {
  /** Статус приемки грузового места */
  status?: 'UNPROCESSED' | 'ACCEPTED' | 'DAMAGED' | 'LOST'
}
export type ShipmentAuthCargoPackagesResource = CargoPackageData & {
  internal?: InternalCargoPackageData
}
export type ShipmentTypesEnum =
  | 'TO_THE_DOOR'
  | 'FROM_THE_DOOR'
  | 'DOOR_TO_DOOR'
  | 'DOOR_WAREHOUSE_DOOR'
  | 'FBO'
  | 'REVERSE_FBO'
  | 'DOCUMENTS_RETURN'
export type ShipmentStatusEnum =
  | 'AT_SENDER_PARSE_ERROR'
  | 'AT_SENDER_NEW'
  | 'AT_SENDER_PACKED'
  | 'AT_SENDER_AWAITING_SELF_PICKUP'
  | 'AT_SENDER_AWAITING_QUBY_PICKUP'
  | 'QUBY_VEHICLE_2_QUBY'
  | 'AT_QUBY_AWAITING_CONFIRMATION'
  | 'AT_QUBY_AWAITING_CORRECTION'
  | 'AT_QUBY_AWAITING_ROUTING'
  | 'AT_QUBY_AWAITING_DELIVERY'
  | 'AGENT_VEHICLE_2_AGENT'
  | 'AT_AGENT'
  | 'AGENT_VEHICLE_2_RECEIVER'
  | 'AGENT_ERROR'
  | 'RETURN_AGENT_VEHICLE_2_QUBY'
  | 'RETURN_AGENT_VEHICLE_2_SENDER'
  | 'AT_AGENT_LOST'
  | 'QUBY_VEHICLE_2_RECEIVER'
  | 'AT_RECEIVER_DONE'
  | 'RETURN_QUBY_VEHICLE_2_QUBY'
  | 'RETURN_AT_QUBY_AWAITING_CONFIRMATION'
  | 'RETURN_AT_QUBY_AWAITING_RETURN'
  | 'RETURN_QUBY_VEHICLE_2_SENDER'
  | 'AT_SENDER_RETURNED'
  | 'AT_SENDER_CANCELLED'
export type Attachment = {
  id?: string
  fileName?: string
  filePath?: string
  /** Date and time when attachment was created */
  createdAt?: string
}
export type ShipmentLog = {
  /** Shipment status */
  status?:
    | 'AT_SENDER_PARSE_ERROR'
    | 'AT_SENDER_NEW'
    | 'AT_SENDER_PACKED'
    | 'AT_SENDER_AWAITING_SELF_PICKUP'
    | 'AT_SENDER_AWAITING_QUBY_PICKUP'
    | 'QUBY_VEHICLE_2_QUBY'
    | 'AT_QUBY_AWAITING_CONFIRMATION'
    | 'AT_QUBY_AWAITING_CORRECTION'
    | 'AT_QUBY_AWAITING_ROUTING'
    | 'AT_QUBY_AWAITING_DELIVERY'
    | 'AGENT_VEHICLE_2_AGENT'
    | 'AT_AGENT'
    | 'AGENT_VEHICLE_2_RECEIVER'
    | 'AGENT_ERROR'
    | 'RETURN_AGENT_VEHICLE_2_QUBY'
    | 'RETURN_AGENT_VEHICLE_2_SENDER'
    | 'AT_AGENT_LOST'
    | 'QUBY_VEHICLE_2_RECEIVER'
    | 'AT_RECEIVER_DONE'
    | 'RETURN_QUBY_VEHICLE_2_QUBY'
    | 'RETURN_AT_QUBY_AWAITING_CONFIRMATION'
    | 'RETURN_AT_QUBY_AWAITING_RETURN'
    | 'RETURN_QUBY_VEHICLE_2_SENDER'
    | 'AT_SENDER_RETURNED'
    | 'AT_SENDER_CANCELLED'
  /** Translated Status */
  translatedStatus?: string
  comment?: string
  updateSource?: string
  /** Date and time when log was created */
  createdAt?: string
}
export type ShortRunRequestResource = {
  /** ID (UUID) */
  id: string
  /** Full ordinal number in the format [client ordinal number]-[run request ordinal number] */
  fullOrdinalNumber: string
  /** Legal form of organization */
  type: 'PICKUP' | 'LOCATION_PICKUP' | 'DELIVERY' | 'RETURN'
  /** Legal form of organization */
  status: 'NEW' | 'IN_PROGRESS' | 'DONE' | 'CANCELLED' | 'ERROR'
  /** arrivalDate */
  arrivalDate: string
  /** arrivalTimeFrom */
  arrivalTimeFrom: string
  /** arrivalTimeTo */
  arrivalTimeTo: string
  /** Date and time when received terminal status (done or cancelled) */
  closedAt: string
  /** Transportation track number in external delivery service */
  deliveryServiceTrackNumber: string
  /** Amount of money quby will pay (or already paid) to the delivery service for this transportation */
  deliveryCost: number
  /** Comment for this run request */
  comment: string
}
export type ShortClientResource = {
  /** ID клиента (UUID) */
  id: string
  /** Конкатенация legal form и legal name клиента */
  fullName: string
  /** Дата и время, когда клиент был удален */
  deletedAt: string
}
export type ShortDeliveryServiceResource = {
  /** Delivery Service`s UUID */
  id: string
  /** Display name */
  name: string
}
export type DlvSelectedService = {
  /** Name of a service (provided by dlv) */
  service?: string
  price?: number
  /** Label of a service (provided by dlv) */
  label?: string
}
export type ShipmentInternalResource = {
  /** Should vehicle go directly from sender to receiver location (true), or be unloaded in QubyWarehouse after pickup */
  isDirectRun: boolean
  /** Amount of money quby will pay (or already paid) to the delivery service */
  deliveryCost: number
  /** Track number for shipment in external delivery service */
  deliveryServiceTrackNumber: string
  client?: ShortClientResource
  deliveryService?: ShortDeliveryServiceResource
  /** Additional services to be provided (or already provided) by external delivery service */
  dlvSelectedServices: DlvSelectedService[]
  /** Contract ID of delivery service used for this shipment in DLV microservice */
  dlvContractId: string
  /** Invoice Id */
  invoiceId: string
  /** insuredAt */
  insuredAt: string
  /** isMarkedForCancel */
  isMarkedForCancel: boolean
  /** reasonOfCancel */
  reasonOfCancel: string
}
export type ShipmentAuthResource = {
  /** Seller of this shipment (usually for FBO purposes and cases where quby client aggregates multiple sellers) */
  seller: string
  /** Unique external (from client system) identifier for the shipment */
  externalId: string
  /** Unique identifier of FBO delivery. Could only be provided when one of locations is FBO marketplace */
  fboDeliveryNumber: string
  pickup: PickupData
  delivery: DeliveryData
  /** Array of additional services to be done or already done by quby */
  additionalServices: ShipmentAdditionalServicesEnum[]
  /** Сформировано паллет */
  numberOfPalletsToForm: number
  /** Кол-во паллет к продаже */
  numberOfPalletsToSell: number
  /** Кол-во часов разовых работ */
  hoursOfOneTimeServices: number
  /** Array of cargo packages */
  cargoPackages: ShipmentAuthCargoPackagesResource[]
  /** Shipment ID (UUID) */
  id: string
  /** Full ordinal number in the format [client ordinal number]-[shipment ordinal number] */
  fullOrdinalNumber: string
  type: ShipmentTypesEnum
  status: ShipmentStatusEnum
  /** Date and time when shipment change last status */
  changeStatusDate: string
  /** Date and time when shipment received terminal status (returned, delivered, cancelled, etc) */
  closedAt: string | null
  /** Translated Type */
  translatedType: string
  /** Translated Status */
  translatedStatus: string
  /** Date and time when shipment was created */
  createdAt: string
  /** Date and time when shipment was updated last time */
  updatedAt: string
  /** Files (usually images) attached to this shipment */
  attachments: Attachment[]
  /** History logs of this shipment */
  logs: ShipmentLog[]
  /** Shipments related to this one (shipments where parentId set to this shipment's id) */
  relatedShipments: ShipmentAuthResource[]
  /** Run Requests related to this one (run requests this shipment included into) */
  relatedRunRequests: ShortRunRequestResource[]
  internal?: ShipmentInternalResource
}
export type ShipmentOrderByTypeEnum =
  | 'ordinalNumber'
  | 'fullOrdinalNumber'
  | 'status'
  | 'id'
  | 'deliveryServiceId'
  | 'createdDate'
  | 'deliveryDate'
export type ShipmentRelationsEnum = 'runRequests' | 'cargoPackages' | 'logs'
export type BaseOrderTypeEnum = 'asc' | 'ASC' | 'desc' | 'DESC'
export type ShipmentStoreCargoPackageData = CargoPackageData
export type InternalShipmentData = {
  /** Should vehicle go directly from sender to receiver location (true), or be unloaded in QubyWarehouse after pickup */
  isDirectRun?: boolean
  /** Amount of money quby will pay (or already paid) to the delivery service */
  deliveryCost?: number
  /** UUID of delivery service used for this shipment */
  deliveryServiceId?: string
  /** Track number for shipment in external delivery service */
  deliveryServiceTrackNumber?: string
  /** Contract ID of delivery service used for this shipment in DLV microservice */
  dlvContractId?: string
  /** Additional services to be provided (or already provided) by external delivery service */
  dlvSelectedServices?: DlvSelectedService[]
}
export type ShipmentStoreRequest = {
  /** Array of cargo packages */
  cargoPackages: ShipmentStoreCargoPackageData[]
  /** Seller of this shipment (usually for FBO purposes and cases where quby client aggregates multiple sellers) */
  seller?: string
  /** Unique external (from client system) identifier for the shipment */
  externalId?: string
  /** Unique identifier of FBO delivery. Could only be provided when one of locations is FBO marketplace */
  fboDeliveryNumber?: string
  pickup: PickupData
  delivery: DeliveryData
  /** Array of additional services to be done or already done by quby */
  additionalServices: ShipmentAdditionalServicesEnum[]
  /** Сформировано паллет */
  numberOfPalletsToForm?: number
  /** Кол-во паллет к продаже */
  numberOfPalletsToSell?: number
  /** Кол-во часов разовых работ */
  hoursOfOneTimeServices?: number
  /** UUID of another shipment this new one should be related to (ex: you want to create documents return shipment and make it linked to initial products shipment) */
  parentShipmentId?: string
  /** Indicates if the shipment needs Quby warehouse */
  needsQubyWarehouse?: boolean
  internal?: InternalShipmentData
}
export type ShipmentUpdateCargoPackageData = CargoPackageData & {
  id?: string
  internal?: InternalCargoPackageData
}
export type ShipmentUpdateRequest = {
  /** Array of cargo packages */
  cargoPackages: ShipmentUpdateCargoPackageData[]
  /** Seller of this shipment (usually for FBO purposes and cases where quby client aggregates multiple sellers) */
  seller?: string
  /** Unique external (from client system) identifier for the shipment */
  externalId?: string
  /** Unique identifier of FBO delivery. Could only be provided when one of locations is FBO marketplace */
  fboDeliveryNumber?: string
  pickup?: PickupData
  delivery?: DeliveryData
  /** Array of additional services to be done or already done by quby */
  additionalServices?: ShipmentAdditionalServicesEnum[]
  /** Сформировано паллет */
  numberOfPalletsToForm?: number
  /** Кол-во паллет к продаже */
  numberOfPalletsToSell?: number
  /** Кол-во часов разовых работ */
  hoursOfOneTimeServices?: number
  /** UUID of another shipment this new one should be related to (ex: you want to create documents return shipment and make it linked to initial products shipment) */
  parentShipmentId?: string
  /** Indicates if the shipment needs Quby warehouse */
  needsQubyWarehouse?: boolean
  internal?: InternalShipmentData
}
export type ShipmentsRegisterExportColumnsEnum =
  | 'INNER_NUMBER'
  | 'EXTERNAL_ID'
  | 'PARTNER_NAME'
  | 'SENDER'
  | 'RECEIVER'
  | 'STATUS'
  | 'TYPE'
  | 'CREATED_DATE'
  | 'INSURANCE_SUM'
  | 'CARGO_PACKAGES_TYPE'
  | 'CARGO_PACKAGES_AMOUNT'
  | 'WEIGHT'
  | 'VOLUME_WEIGHT'
  | 'ACTUAL_PICKUP_TIME'
  | 'ACTUAL_DELIVERY_TIME'
  | 'INVOICE'
  | 'DELIVERY_SERVICE'
  | 'REASON_OF_CANCEL'
  | 'DESIRED_PICKUP_INTERVAL'
  | 'PLANNED_PICKUP_INTERVAL'
  | 'DESIRED_DELIVERY_INTERVAL'
  | 'PLANNED_DELIVERY_INTERVAL'
  | 'GOODS'
  | 'RELATED_RUN_REQUESTS'
  | 'DELIVERY_COST'
  | 'DELIVERY_SERVICE_TRACK_NUMBER'
  | 'SHIPMENT_COMMENT'
export type DataForCalculatorGetTariffsSenderLocationResource = {
  area: string
  city: string
  cityArea: string
  cityFiasId: string
  country: string
  fiasId: string
  region: string
  regionFiasId: string
  settlement: string
  settlementFiasId: string
  zipcode: string
}
export type DataForCalculatorGetTariffsReceiverLocationResource = {
  area: string
  city: string
  cityArea: string
  cityFiasId: string
  country: string
  fiasId: string
  region: string
  regionFiasId: string
  settlement: string
  settlementFiasId: string
  zipcode: string
}
export type DataForCalculatorGetTariffsResource = {
  /** Shipment type */
  shipmentType:
    | 'TO_THE_DOOR'
    | 'FROM_THE_DOOR'
    | 'DOOR_TO_DOOR'
    | 'DOOR_WAREHOUSE_DOOR'
    | 'FBO'
    | 'REVERSE_FBO'
    | 'DOCUMENTS_RETURN'
  /** senderLocation */
  senderLocation: DataForCalculatorGetTariffsSenderLocationResource[]
  /** senderLocation */
  receiverLocation: DataForCalculatorGetTariffsReceiverLocationResource[]
}
export type VehicleCapacityVolumeResource = {
  /** height */
  height: number
  /** width */
  width: number
  /** depth */
  depth: number
}
export type VehicleCapacityResource = {
  /** weight */
  weight: number
  volume: VehicleCapacityVolumeResource
}
export type VehicleResource = {
  /** Driver`s UUID */
  id: string
  /** brand */
  brand: string
  /** plateNumber */
  plateNumber: string
  /** description */
  description: string
  capacity: VehicleCapacityResource
  /** numberSts */
  numberSts: string
  /** numberVin */
  numberVin: string
  /** createdAt */
  createdAt: string
  /** Активный? */
  isActive: boolean
}
export type VehicleCapacityVolumeRequest = {
  /** height */
  height: number
  /** width */
  width: number
  /** depth */
  depth: number
}
export type VehicleCapacityRequest = {
  /** weight */
  weight: number
  volume: VehicleCapacityVolumeRequest
}
export type VehicleStoreRequest = {
  /** brand */
  brand: string
  /** plateNumber */
  plateNumber: string
  /** description */
  description: string
  capacity: VehicleCapacityRequest
  /** numberSts */
  numberSts: string
  /** numberVin */
  numberVin: string
  /** Активный? */
  isActive: boolean
}
export type VehicleUpdateRequest = VehicleStoreRequest
