import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig } from 'axios'
import pick from 'lodash/pick'
import { QueryBaseError } from '@/api/axiosBaseQuery.types'
import Api from '@/services/api/api'
// @ts-ignore
import { BASE_DLV_API_URL } from '@/constants/env'
import qs from 'qs'

export const axiosBaseQueryDlv =
  (
    api: Api,
  ): BaseQueryFn<
    AxiosRequestConfig & { body?: unknown },
    unknown,
    QueryBaseError
  > =>
  async (conf) => {
    const { url, method, params, body: data, headers } = conf

    const baseURL = BASE_DLV_API_URL
    // TODO: здесь эта затея выглядит странно. Убрать после выпиливания Java
    const resultUrl = url?.replace(/^\/v1\//, '/')
    const isV1Dot1 = Boolean(url?.match(/\/v1\.1\//))

    try {
      const result = await api.axios({
        ...(isV1Dot1 ?
          {
            paramsSerializer: {
              serialize: (params) => {
                return qs.stringify(params)
              },
            },
          }
        : {}),
        url: resultUrl,
        method,
        data,
        params,
        headers,
        baseURL,
      })
      return { data: result.data }
    } catch (axiosError) {
      const error = axiosError as QueryBaseError
      return {
        error: {
          ...pick(error, ['message', 'code', 'name']),
          response: JSON.parse(
            JSON.stringify(
              pick(error.response || {}, [
                'data',
                'status',
                'statusText',
                'headers',
              ]),
            ),
          ),
        },
      }
    }
  }
